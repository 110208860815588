import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const sharedPaths = [
  "/login",
  "/signup",
  "/password-revovery",
  "/password-reset",
  "/too-many-attempts",
  "/username-recovery",
  "/add-a-line",
  "/remove-a-line",
  "/group-payment",
  "/page-not-found",
  "/mgmt",
  "/wireless-service-agreement",
  "/wireless-critical-agreement",
];

const getTheHomeRedirect = () =>
  store.state.login.clientType === "prepaid" ? "/prepaid-home" : "/home";

const routes = [
  {
    path: "/",
    redirect: getTheHomeRedirect(),
  },
  // postpaid & prepaid shared pages
  {
    path: "/login",
    name: "Login",
    component: () => import("../views-shared/LoginPage/Login.vue"),
  },
  {
    path: "/username-recovery",
    name: "UsernameRecovery",
    component: () => import("../views-shared/LoginPage/UsernameRecovery.vue"),
  },
  {
    path: "/password-revovery",
    name: "PasswordRecovery",
    component: () => import("../views-shared/LoginPage/PasswordRecovery.vue"),
  },
  {
    path: "/password-reset",
    name: "ResetTempPassword",
    component: () => import("../views-shared/LoginPage/ResetTempPassword.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("../views-shared/LoginPage/SignUp.vue"),
  },
  {
    path: "/too-many-attempts",
    name: "TooManyAttempts",
    component: () => import("../views-shared/LoginPage/TooManyAttempts.vue"),
  },
  {
    path: "/add-a-line",
    name: "AddALine",
    component: () => import("../views-shared/MultiLinesPage/AddALine.vue"),
  },
  {
    path: "/remove-a-line",
    name: "RemoveALine",
    component: () => import("../views-shared/MultiLinesPage/RemoveALine.vue"),
  },

  {
    path: "/group-payment",
    name: "GroupPayment",
    component: () => import("../views-shared/MultiLinesPage/GroupPayment.vue"),
  },
  {
    path: "/page-not-found",
    name: "PageNotFound",
    component: () => import("../views-shared/OtherPage/404.vue"),
  },
  {
    path: "/mgmt",
    name: "MGMT",
    component: () => import("../views-shared/OtherPage/MGMT.vue"),
  },
  {
    path: "/wireless-service-agreement",
    name: "WirelessServiceAgreement",
    component: () =>
      import("../views-shared/OtherPage/WirelessServiceAgreement.vue"),
  },
  {
    path: "/wireless-critical-agreement",
    name: "WirelessCriticalAgreement",
    component: () =>
      import("../views-shared/OtherPage/WirelessAndCriticalInfo.vue"),
  },
  {
    path: "/critical-information-summary",
    name: "CriticalInformationSummary",
    component: () =>
      import("../views-shared/OtherPage/CriticalInformationSummary.vue"),
  },
  { path: "*", redirect: "/page-not-found" },
  // postpaid pages
  {
    path: "/home",
    name: "Home",
    component: () => import("../views-postpaid/HomePage/Home.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views-postpaid/ProfilePage/Profile.vue"),
  },
  {
    path: "/plan",
    name: "Plan",
    component: () => import("../views-postpaid/PlanPage/Plan.vue"),
  },
  {
    path: "/other-plans",
    name: "OtherPlans",
    component: () => import("../views-postpaid/PlanPage/OtherPlans.vue"),
  },
  {
    path: "/billing",
    name: "Billing",
    component: () => import("../views-postpaid/BillingPage/Billing.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views-shared/SupportPage/Support.vue"),
  },
  {
    path: "/postpaid-invoice-pdf",
    name: "PostpaidInvoicePDF",
    component: () => import("../views-postpaid/BillingPage/InvoicePDF.vue"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("../views-postpaid/BillingPage/Payment.vue"),
  },
  // prepaid pages
  {
    path: "/prepaid-home",
    name: "PrepaidHome",
    component: () => import("../views-prepaid/HomePage/Home.vue"),
  },
  {
    path: "/prepaid-profile",
    name: "PrepaidProfile",
    component: () => import("../views-prepaid/ProfilePage/Profile.vue"),
  },
  {
    path: "/prepaid-payment",
    name: "PrepaidPayment",
    component: () => import("../views-prepaid/PaymentPage/Payment.vue"),
    props: {
      name: "",
      amount: 0,
    },
  },
];

/**
 * page change -> scrool to top
 */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

/**
 * Navigation Guard
 * first time to the website -> go to the login page
 * regular routing -> check postpaid pages & prepaid pages
 * block postpaid paths for prepaid clients
 * block prepaid paths for postpaid clients
 */
router.beforeEach((to, from, next) => {
  if (sharedPaths.includes(to.path)) {
    if (
      to.path !== "/add-a-line" &&
      to.path !== "/remove-a-line" &&
      to.path !== "/group-payment"
    ) {
      store.commit("setHasNotLogedInStatus", true);
    }
    next();
  } else {
    store.commit("setLogInStates", {
      key: "preRoute",
      value: to.path,
    });
    let token = store.state.login.token;
    let clientType = store.state.login.clientType;
    if (token === null || token === "") {
      store.commit("setHasNotLogedInStatus", true);
      next("/login");
    } else {
      store.commit("setHasNotLogedInStatus", false);
      if (clientType === "prepaid") {
        to.path.split("-")[0] === "/prepaid" ? next() : next("page-not-found");
      } else if (clientType === "postpaid") {
        to.path.split("-")[0] === "/prepaid" ? next("page-not-found") : next();
      } else {
        store.dispatch("logout");
      }
    }
  }
});

/**
 * remove NavigationDuplicated error
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
