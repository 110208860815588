import Vue from "vue";
import Vuex from "vuex";
import axios from "../utils/axios";
import createPersistedState from "vuex-persistedstate";
import router from "../router";

Vue.use(Vuex);

/**
 * Global Parameters
 *
 * @param {isLoading} boolean -> loading animation
 * @param {hasNotLogedIn} boolean -> auth status
 * @param {currentNav} string -> postpaid header nav item color
 * @param {prepaidCurrentNav} string -> prepaid header nav item color
 * @param {login} object -> auth & routing status
 * @param {mutilLinesAccounts} array -> all multi-lines members including postpaid & prepaid
 * @param {prepaidServiceData} object -> payload for prepaid payment
 * @param {postpaidServiceData} object -> payload for postpaid payment
 *
 */
const getDefaultState = () => {
  return {
    isLoading: false,
    hasNotLogedIn: true,
    currentNav: "Home",
    prepaidCurrentNav: "Home",
    login: {
      clientType: "",
      token: "",
      cnum: "",
      cnumAcctOwner: "",
      cnumAcctOwnerSaved: "",
      preRoute: null,
    },
    mutilLinesAccounts: [],
    prepaidServiceData: {},
    postpaidServiceData: {},
    pdfInvoiceData: {
      postpaid: {
        year: "",
        month: "",
      },
    },

    // Terms and Conditions for PAD payment Nov 15, 2021
    isTermsOpened: false,
  };
};

const state = getDefaultState();

const mutations = {
  setLoadingTrue(state) {
    state.isLoading = true;
  },
  setLoadingFalse(state) {
    state.isLoading = false;
  },
  setHasNotLogedInStatus(state, data) {
    state.hasNotLogedIn = data;
  },
  setCurrentNav(state, current) {
    state.currentNav = current;
  },
  setPrepaidCurrentNav(state, current) {
    state.prepaidCurrentNav = current;
  },
  setpostpaidServiceData(state, current) {
    state.postpaidServiceData = current;
  },
  setprepaidServiceData(state, current) {
    state.prepaidServiceData = current;
  },
  setLogInStates(state, payload) {
    state.login[payload.key] = payload.value;
  },
  setPostpaidPdfInvoiceData(state, payload) {
    state.pdfInvoiceData.postpaid[payload.key] = payload.value;
  },
  setMultiLinesAccountData(state, value) {
    state.mutilLinesAccounts = value;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setIsTermsOpened(state, data) {
    state.isTermsOpened = data;
  },
};

const actions = {
  logout() {
    router.push("/login");
    axios
      .get("/LoginRegistration/Logout")
      .then(() => {
        this.commit("resetState");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  initMultilinesData() {
    axios
      .get("Account/MultiLineAllAccountsInfo")
      .then((response) => {
        this.commit("setMultiLinesAccountData", JSON.parse(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state,
  mutations,
  actions,
  modules: {},
});
