<template>
  <div class="headerWrapper">
    <div class="pureheader">
      <b-container>
        <a href="https://gophonebox.com/">
          <b-img
            src="https://gophonebox.com/images/logo.png"
            fluid
            id="logo"
            alt="PhoneBox Logo"
          ></b-img>
        </a>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "PureHeader",
};
</script>

<style lang="css" scoped>
.pureheader {
  background-color: white !important;
  padding: 30px;
  box-shadow: rgba(51, 51, 51, 0.5) 0px 0px 10px;
}

#logo {
  height: 36px;
}

#logo:hover {
  cursor: pointer;
}
</style>
