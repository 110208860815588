<template>
  <div id="app" v-loading="$store.state.isLoading">
    <div v-if="hasNotLoggedIn">
      <PureHeader />
    </div>
    <div v-else>
      <div v-if="isPostpaid">
        <Header />
      </div>
      <div v-else>
        <PrepaidHeader />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <div v-show="!hasNotLoggedIn">
      <Footer />
    </div>
  </div>
</template>

<script>
import PureHeader from "./components/PureHeader.vue";
import Header from "./components/Header";
import PrepaidHeader from "./components/PrepaidHeader.vue";
import Footer from "./components/Footer";
import Elevio from 'elevio/lib/client';

// import router from "./router/index";
import store from "./store/index";
export default {
  name: "App",
  components: {
    PureHeader,
    Header,
    PrepaidHeader,
    Footer,
  },
  data() {
    return {};
  },
  computed: {
    hasNotLoggedIn() {
      return this.$store.state.hasNotLogedIn;
    },
    isPostpaid() {
      return this.$store.state.login.clientType === "postpaid" ? true : false;
    },
    // mainStyle() {
    //   if (
    //     this.$store.state.hasNotLogedIn ||
    //     this.$store.state.currentNav === "404"
    //   ) {
    //     return {
    //       background: "linear-gradient(to bottom, #0194e9, #35c1d0)",
    //       paddingBottom: "0px",
    //     };
    //   } else {
    //     let bg =
    //       this.$route.path === "/add-a-line" ||
    //       this.$route.path === "/remove-a-line"
    //         ? "linear-gradient(to bottom, #0194e9, #35c1d0)"
    //         : "#f2fafc";
    //     return {
    //       background: bg,
    //       paddingBottom: "200px",
    //     };
    //   }
    // },
  },
  created() {
    window.onbeforeunload = function() {
      window.localStorage.unloadTime = JSON.stringify(new Date());
    };
    // check the refresh time
    //  if the time is > 10 mins which means it is a closing page event instead of refreshing page event
    window.onload = function() {
      let loadTime = new Date();
      let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
      let refreshTime = loadTime.getTime() - unloadTime.getTime();
      if (refreshTime > 1000 * 60 * 10) {
        // router.push("/login");
        // store.commit("resetState");
        store.dispatch("logout");
      }
    };
    // Add Elevio
    Elevio.load('604012fe69c1d')

    // Adding Clarity
    const clarityScript = document.createElement("script");
    clarityScript.setAttribute("type", "text/javascript");
    clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "n1tfr64lai");
    `;
    document.head.appendChild(clarityScript);
  },
  onIdle() {
    if (!this.$store.state.hasNotLogedIn) {
      // this.$router.push("/login");
      // this.$store.commit("resetState");
      this.$store.dispatch("logout");
      this.$alert(
        "Since there was no activity during the last 10 minutes, we have logged you out automatically. Please log in again to access the account. Thank you.",
        "Auto Log Out",
        {
          confirmButtonText: "Confirm",
        }
      );
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

#app {
  margin: 0 !important;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  position: relative;
  padding-top: 90px;
  background-color: #f2fafc;
  padding-bottom: 200px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s, transform 1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-10%);
}

a {
  text-decoration: none !important;
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-image: url("assets/referral.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.terms-link {
  text-decoration: underline !important;
  color: white;
  cursor: pointer;

  :hover {
    color: white !important;
  }
}

.headerWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}

.footerWrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
}

i {
  color: #1cabdc;
  font-size: 25px;
}

i:hover {
  cursor: pointer;
}

.text-main {
  color: #45c7d3;
}

.text-diy {
  color: #36c2cf;
}

.text-grey {
  color: #d2d2d2;
}

.text-blue {
  color: #00bae5;
}

.text-dark-blue {
  color: #0394ca;
}

.bg-grey {
  background-color: rgb(238, 234, 234);
}

.bg-white {
  background-color: #fff;
}

.bg-main {
  background-color: #ebf9fa;
}

.bg-sec {
  background-color: #d9f4f6;
}

.bg-blue {
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
}

.bg-pb-blue {
  background-color: rgba(4, 148, 202, 0.8);
}

.bg-green {
  background-image: linear-gradient(to bottom, #35c1d0, #35c1d0);
}

.bg-block {
  background-image: linear-gradient(to bottom, #cacaca, #cacaca);
}

.mycard {
  border-radius: 10px;
}

.border-radius-up {
  border-radius: 10px 10px 0px 0px;
}

.border-radius-all {
  border-radius: 10px 10px 10px 10px;
}

.border-radius-down {
  border-radius: 0px 0px 10px 10px;
}

.w-300 {
  font-weight: 300;
}

.w-500 {
  font-weight: 500;
}

.w-700 {
  font-weight: 700;
}

.w-900 {
  font-weight: 900;
}

p,
small,
li {
  font-weight: 300;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400 !important;
}

.bar:hover,
.icon:hover {
  cursor: pointer;
}

.offer-text {
  color: #0494CA;
  font-weight: 600;
  font-size: 20px;
}

.terms-header {
  color: #00bae5;
  font-weight: 600;
  padding-bottom: 8px;
}

.terms-text {
  font-size: 14px;
}

.my-input {
  background-color: #ebf9fa !important;
  border: solid #fff 0px !important;
  font-size: 16px;
}

.mybtn {
  padding: 10px 40px;
  border-radius: 50px;
  border: 0px;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to right, #c43d29, #e83e25);
  text-transform: uppercase;
}

.mybtn-blue {
  padding: 10px 40px;
  border-radius: 50px;
  border: 0px;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  text-transform: uppercase;
}

.mybtn-white-outline {
  padding: 5px 25px;
  border-radius: 10px;
  border: 1px solid #fff;
  max-height: 42px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.mybtn-red-block {
  padding: 5px 25px;
  border-radius: 10px;
  border: 1px solid #E93F25;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-color: #E93F25;
}

.mybtn-block {
  padding: 10px 40px;
  border-radius: 50px;
  border: 1px solid #a5a5a5;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #a5a5a5, #a5a5a5);
  text-transform: uppercase;
}

.mobileNav {
  display: none;
}

.el-popover {
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.help {
  p {
    font-size: 15px;
  }
}

.prepaidPageSection {
  padding-top: 20px;
  padding-bottom: 30px;
}

.buttonStyle:hover {
  cursor: pointer;
}

.my-dropdown {

  // Set the color of the dropdown toggle in default state
  .dropdown-toggle {
    background-color: #45c7d3;
    border-color: #45c7d3;
  }

  // Set the color of the dropdown toggle when its hovered

  // .dropdown-toggle:hover {
  //   // background-color: #45c7d3;
  // }

  //Set the color of the dropdown toggle when its in focus
  .dropdown-toggle:focus {
    background-color: #45c7d3;
    box-shadow: none;
  }

  // Set color of dropdown item when item is selected
  .dropdown-item:active {
    background-color: #45c7d3;
    color: #ffffff;
  }
}

.multiselect__input,.multiselect__single, .multiselect__tags {
  background-color: #ebf9fa;
  border: 0px solid #ebf9fa;
}

.multiselect__option--highlight, .multiselect__option--highlight:after{
  background-color: #45c7d3;
}


.my-dimmed {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 768px) {
  .mobileNav {
    display: block;
    margin-top: -8px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    background-color: #e9f8fc;
    color: #45c7d3;
  }

  .prepaidPageSection {
    padding-top: 0px;
  }
}

@import url('https://fonts.cdnfonts.com/css/damion');

.list-of-countries {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.img-background {
  height: fit-content;
  background: linear-gradient(0deg, rgba(1, 148, 233, 0.7), rgba(53, 193, 208, 0.7)), url('assets/people-background.png');
  background-size: cover;
  border-radius: 5px;
}

.white-cursive-text {
  font-family: "Damion";
  color: white;
  font-size: 32px;
  width: fit-content;
  margin: auto;
  padding: 12px;
}

.column-container {
  max-width: 80%; 
  margin: auto;
  padding: 12px;
  padding-bottom: 36px;
}

.responsive-columns {
  color: white;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 12px);
  grid-auto-flow: column;
  gap: 16px; /* Adjust the gap between columns as needed */
  white-space: nowrap;

  @media (max-width: 1220px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(10, 12px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(16, 12px);
  }
}

</style>
