import axios from "axios";
import router from "../router/index";
import store from "../store/index";

// axios.defaults.withCredentials = false;

// debugging
let myapiurl = "https://phoneboxapi.azurewebsites.net/api";
const mywebsite = window.location.href;
if (mywebsite.includes("azurewebsites.net"))
  myapiurl = "https://testmyaccount.azurewebsites.net/api"
else if (mywebsite.includes("localhost:8080"))
  myapiurl = "https://testmyaccount.azurewebsites.net/api/"

const instance = axios.create({
  // testing url
  // baseURL: "https://testmyaccount.azurewebsites.net/api/",
  baseURL: myapiurl,
  timeout: 20000,
  headers: {},
});

// const instance = axios.create({
//   // testing url
//   //baseURL: "https://testmyaccount.azurewebsites.net/api/",
//   // production URL
//   baseURL: "https://phoneboxapi.azurewebsites.net/api",
//   timeout: 8000,
//   headers: {},
// });

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    // put token & cnum into headers
    Object.assign(config.headers, {
      cnum: store.state.login.cnum,
      cnumAcctOwner: store.state.login.cnumAcctOwner,
      token: store.state.login.token,
      client: "AccountArea",
      apitoken: "aRsriq+92Ei2LKPOJQhbS6v7c4kx7lPx",
    });
    if (
      router.currentRoute.path !== "/payment" &&
      router.currentRoute.path !== "/prepaid-payment" &&
      router.currentRoute.path !== "/group-payment"
    ) {
      store.commit("setLoadingTrue");
    }
    return config;
  },
  function(error) {
    // Do something with request error
    store.commit("setLoadingFalse");
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return JSON.parse(response.data);
    store.commit("setLoadingFalse");
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // handle with expired token (401 error) ->
    // go back to login page & clear token and cnum
    store.commit("setLoadingFalse");
    if (error.response && error.response.status === 401) {  // add [error.response] since it can be undefined
      router.push("/login");
      store.commit("resetState");
    }
    return Promise.reject(error);
  }
);

export default instance;
