<template>
  <div
    class="navigation d-flex flex-row justify-content-center align-items-center p-3"
  >
    <div class="navItemWrapper" :class="checkNavHome" @click="handleClickHome">
      <h3>Home</h3>
    </div>

    <div
      class="navItemWrapper"
      :class="checkNavProfile"
      @click="handleClickProfile"
    >
      <h3>Profile</h3>
    </div>
    <div class="navItemWrapper d-none" :class="checkNavPlan" @click="handleClickPlan">
      <h3>Plan</h3>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import router from "../router/index";
export default {
  methods: {
    handleClickHome() {
      store.commit("setPrepaidCurrentNav", "Home");
      router.push("/prepaid-home");
    },

    handleClickProfile() {
      store.commit("setPrepaidCurrentNav", "Profile");
      router.push("/prepaid-profile");
    },

    handleClickPlan() {
      store.commit("setPrepaidCurrentNav", "Plan");
      router.push("/prepaid-plan");
    },
  },
  computed: {
    checkNavHome() {
      return store.state.prepaidCurrentNav === "Home" ? "active" : "";
    },
    checkNavProfile() {
      return store.state.prepaidCurrentNav === "Profile" ? "active" : "";
    },
    checkNavPlan() {
      return store.state.prepaidCurrentNav === "Plan" ? "active" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: initial;
}

.navigation a {
  text-decoration: none;
  color: #131313;
}

.active {
  color: #54bce3;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;  
}

.nav-item {
  text-align: center;
  width: 90%;
}

.navItemWrapper {
  h3 {
    font-size: 18px;
    font-weight: 300 !important;
    margin: 0;
    padding: 10px 24px;
  }
  margin: 10px 13px;
}

.navItemWrapper:hover {
  cursor: pointer;
}
</style>
