<template>
  <div>
    <el-popover placement="bottom" width="300" trigger="hover">
      <div v-for="el in accData" :key="el.Pnum">
        <div
          @click="switchAccount(el)"
          class="eachAccount d-flex flex-row justify-content-start align-items-center py-2"
        >
          <div class="iconWrapper">
            <img src="../assets/current-line.png" width="35px" />
          </div>
          <div>
            <p>
              {{ el.FirstName + " " + el.LastName }}
              ({{ el.AccountType === "prepaid" ? "Prepaid" : "Monthly" }})
            </p>
            <p>{{ processPhoneFormat(el.Pnum) }}</p>
          </div>
          <div v-show="checkIsCurrentAccount(el.Cnum)">
            <p class="ml-4" style="color: #19A9DD;">✓</p>
          </div>
        </div>
      </div>

      <!-- <el-divider></el-divider> -->

      <!-- add an account  -->
      <div v-show="checkIsMainAccount()">
        <div
          @click="$router.push('/add-a-line')"
          class="eachAccount d-flex flex-row justify-content-start align-items-center"
        >
          <div class="iconWrapper">
            <img src="../assets/add-line.png" width="35px" />
          </div>
          <p>Add an Account</p>
        </div>
      </div>

      <!-- remove an account  -->
      <div v-show="hasMultiMembers">
        <div v-show="checkIsMainAccount()">
          <div
            @click="$router.push('/remove-a-line')"
            class="eachAccount d-flex flex-row justify-content-start align-items-center"
          >
            <div class="iconWrapper">
              <img src="../assets/remove-line.png" width="35px" />
            </div>
            <p>Remove an Account</p>
          </div>
        </div>
      </div>

      <!-- log out -->
      <div @click="handleLogOut()">
        <div
          class="eachAccount d-flex flex-row justify-content-start align-items-center"
        >
          <div class="iconWrapper">
            <img src="../assets/logout.png" width="35px" />
          </div>
          <p>Log Out</p>
        </div>
      </div>

      <!-- displayed icon  -->
      <div slot="reference">
        <div class="text-center text-blue my-account-icon">
          <i class="fas fa-user d-block"></i>
          <small style="font-weight: 300">My Account</small>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  computed: {
    accData() {
      return this.$store.state.mutilLinesAccounts;
    },
    accountOwnerCnum() {
      return this.$store.state.login.cnumAcctOwnerSaved;
    },
    hasMultiMembers() {
      return this.$store.state.mutilLinesAccounts.length > 1;
    },
  },
  methods: {
    handleLogOut() {
      this.$store.dispatch("logout");
    },
    processPhoneFormat(value) {
      return `+1 (${value.slice(0, 3)}) -
       ${value.slice(3, 6)} -
       ${value.slice(6, 10)}`;
    },
    checkIsCurrentAccount(value) {
      return value === this.$store.state.login.cnum;
    },
    checkIsMainAccount() {
      return this.$store.state.login.cnumAcctOwner === "";
    },
    switchAccount(value) {
      if (value.Cnum === this.accountOwnerCnum) {
        this.$store.commit("setLogInStates", {
          key: "cnumAcctOwner",
          value: "",
        });
        this.$store.commit("setLogInStates", {
          key: "cnum",
          value: this.accountOwnerCnum,
        });
      } else {
        this.$store.commit("setLogInStates", {
          key: "cnumAcctOwner",
          value: this.accountOwnerCnum,
        });
        this.$store.commit("setLogInStates", {
          key: "cnum",
          value: value.Cnum,
        });
      }
      let isSwitchBetweenSameAccountTypes =
        value.AccountType === this.$store.state.login.clientType;
      if (value.AccountType === "prepaid") {
        this.$store.commit("setLogInStates", {
          key: "clientType",
          value: "prepaid",
        });
        this.$router.push("/prepaid-home");
      } else {
        this.$store.commit("setLogInStates", {
          key: "clientType",
          value: "postpaid",
        });
        this.$router.push("/home");
      }
      if (isSwitchBetweenSameAccountTypes) {
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.my-account-icon {
  font-size: 20px;
  margin-top: 12px;
  margin-right: 20px;
  text-decoration: none !important;
}
.my-account-icon:hover {
  opacity: 0.8;
  transition: 0.4s;
  cursor: pointer;
}

.small-nav {
  color: #131313;
}

.small-nav:hover {
  color: #55c0e5;
}

.iconWrapper {
  margin: 10px;
}

.iconBtn {
  margin: 10px 0px;
  border: 2px solid #afdff6;
  border-radius: 20px;
  padding: 5px 20px;
  font-weight: 400;
  background-color: white;
  color: #41b1e6;
  outline: none;
}

.eachAccount:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@media (max-width: 768px) {
  .my-account-icon {
    margin: 0px;
  }
  .my-account-icon small {
    display: none;
  }
}
</style>
