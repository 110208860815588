<template>
  <div class="footerWrapper shadow-sm">
    <div class="text-left text-light footer" :class="calculatedFooterWidth">
      <div>
        <img src="../assets/logo_white.png" class="logo"/>
      </div>
      <div class="d-flex flex-row align-items-center pl-8 justify-content-between flex-wrap">
        <div class="d-flex flex-row align-items-center social-links">
          <span class="social-text">Social Media</span>
          <span class="divider">|</span>
          <a 
            href="https://www.facebook.com/gophonebox"
            class="social-link"
          >
            <i class="fab fa-facebook-square"></i> Facebook
          </a>
          <a 
            href="https://twitter.com/gophonebox"
            class="social-link"
          >
            <i class="fab fa-twitter-square"></i> Twitter
          </a>
          <a 
            href="https://www.instagram.com/gophonebox/"
            class="social-link"
          >
            <i class="fab fa-instagram"></i> Instagram
          </a>
        </div>
        <a 
          href="https://blog.gophonebox.com/"
          class="social-blog d-flex flex-row align-items-center"
        >
          <span class="social-text">Blog</span>
          <span class="divider">|</span>
          <span class="social-description">All Phonebox activities in one site</span>
        </a>
        <div class="d-flex flex-row align-items-center">
          <a 
            @click="$router.push('/wireless-service-agreement')"
            target="_blank"
            class="terms-link"
          >
            Wireless Service Agreement
          </a>
          <span class="mx-2">|</span>
          <a 
            @click="$router.push('/critical-information-summary')"
            target="_blank"
            class="terms-link"
          >
            Critical Information Summary
          </a>
        </div>
      </div>
      <p>
        © {{ year }} <a href="https://gophonebox.com/">PhoneBox.</a> All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: "",
      windowWidth: window.innerWidth
    };
  },
  computed: {
    calculatedFooterWidth() {
      if (this.windowWidth <= 576) {
        return 'footer-sm'
      }
      if (this.windowWidth <= 768) {
        return 'footer-md'
      }
      if (this.windowWidth <= 990) {
        // 130
        return 'footer-lg'
      }
      if (this.windowWidth <= 1200) {
        return 'footer-xl'
      }
      return 'footer-xxl';
    }
  },
  mounted() {
    this.year = new Date().getFullYear();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    })
  },
};
</script>

<style lang="scss" scoped>
.footerWrapper {
  // position: fixed;
  // max-width: 100%;
  background-color: rgb(4, 148, 202);
  padding: 20px;
}
.footer {
  background-color: rgb(4, 148, 202) !important;
  // padding: 10px;
  font-size: 12px;
  width: 650px;
  margin-left: 300px;
}

.logo {
  width: 100px;
  padding: 8px 0px 8px 0px;
}

.footer-sm {
  margin-left: 0px;
  width: 100%;
}

.footer-md {
  margin-left: 0px;
  width: 620px;
}

.footer-lg {
  margin-left: 50px;
  width: 650px;
}

.footer-xl {
  margin-left: 130px;
}

.footer-xxl {
  margin-left: 220px;
}

.social-description {
  padding-left: 8px;
}
.social-link {
  font-size:12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.terms-link {
  font-size:12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.social-text {
  font-weight: 500;
}

.divider {
  margin-left: 4px;
}

i {
  font-size: 14px;
  margin: 8px;
  color: #fff;
}

p {
  font-size: 12px;
  margin-top: 8px;
}

a {
  color: #fff;
}

a:hover {
  color: #fff;
}
</style>
