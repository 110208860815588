<template>
  <div class="headerWrapper">
    <div class="header">
      <b-container
        class="d-flex flex-row justify-content-between align-items-center"
      >
        <b-img
          src="https://gophonebox.com/images/logo.png"
          fluid
          id="logo"
          alt="PhoneBox Logo"
          @click="handleClickLogo"
        ></b-img>

        <div class="headerNav">
          <Navigation />
        </div>

        <MyAccountIcon />

        <!-- <div @click="handleLogout">
          <router-link to="/login">
            <div class="text-center text-blue my-account-icon">
              <i class="fas fa-user d-block"></i>
              <small style="font-weight: 300">Log Out</small>
            </div>
          </router-link>
        </div> -->

        <!-- <div>
          <el-popover placement="bottom" width="180" trigger="click">
            <router-link to="/multi-lines">
              <p class="small-nav my-2">
                Manage My Account
              </p>
            </router-link>
            <router-link to="/login">
              <p
                class="small-nav my-2"
                @click="handleLogout"
              >
                Log Out
              </p>
            </router-link>
            <div slot="reference">
              <div class="text-center text-blue my-account-icon">
                <i class="fas fa-user d-block"></i>
                <small style="font-weight: 300">My Account</small>
              </div>
            </div>
          </el-popover>
        </div> -->

        <div class="mobileMenuWrapper">
          <div id="mobileBtn" @click="show = !show">
            <svg
              id="svg"
              class="ham hamRotate ham1"
              :class="handleNavItemClick"
              viewBox="0 0 100 100"
              width="60"
              onclick="this.classList.toggle('active')"
            >
              <path
                class="line top"
                d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
              />
              <path class="line middle" d="m 30,50 h 40" />
              <path
                class="line bottom"
                d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
              />
            </svg>
          </div>
        </div>
      </b-container>
    </div>

    <el-collapse-transition>
      <div class="text-center content" v-show="show">
        <div @click="show = !show">
          <router-link to="/home">
            <h3 class="shadow-sm p-3">Home</h3>
          </router-link>
        </div>

        <div @click="show = !show">
          <router-link to="/profile">
            <h3 class="shadow-sm p-3">Profile</h3>
          </router-link>
        </div>

        <div @click="show = !show">
          <router-link to="/plan">
            <h3 class="shadow-sm p-3">Plan</h3>
          </router-link>
        </div>

        <div @click="show = !show">
          <router-link to="/billing">
            <h3 class="shadow-sm p-3">Billing</h3>
          </router-link>
        </div>

        <div @click="show = !show">
          <router-link to="/support">
            <h3 class="shadow-sm p-3">Support</h3>
          </router-link>
        </div>

        <!-- <div @click="handleLogout">
          <router-link to="/login">
            <h3 class="shadow-sm p-3">Log Out</h3>
          </router-link>
        </div> -->
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import router from "../router/index";
import store from "../store/index";
import Navigation from "../components/Navigation";
import MyAccountIcon from "../components/MyAccountIcon";
export default {
  name: "Header",
  components: {
    Navigation,
    MyAccountIcon,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    handleNavItemClick() {
      return this.show ? "active" : "";
    },
  },
  methods: {
    handleClickLogo() {
      this.show = false;
      if (this.$route.name !== "Home") {
        store.commit("setCurrentNav", "Home");
        router.push("home");
      }
    },
    // handleLogout() {
    //   this.show = false;
    //   this.$store.commit("resetState");
    //   this.$store.dispatch("logout");
    // },
  },
};
</script>

<style lang="css" scoped>
p {
  margin: 0px;
}

.header {
  background-color: white !important;
  box-shadow: rgba(51, 51, 51, 0.5) 0px 0px 10px;
}

.content {
  z-index: 99;
  background-color: #fff;
}

#logo {
  width: 170px;
}

#logo:hover {
  cursor: pointer;
}

/* .my-account-icon {
  font-size: 20px;
  margin-top: 12px;
  margin-right: 20px;
  text-decoration: none !important;
}
.my-account-icon:hover {
  opacity: 0.8;
  transition: 0.4s;
  cursor: pointer;
}

.small-nav {
  color: #131313;
}

.small-nav:hover {
  color: #55c0e5;
} */

h3 {
  font-size: 18px;
  color: #131313;
  text-decoration: none;
  font-weight: 500 !important;
}

.navitem {
  text-decoration: none;
}

.mobileMenuWrapper {
  display: none;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #00bae5;
  stroke-width: 4;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

@media (max-width: 992px) {
  .header {
    padding: 10px 10px 10px 17px;
  }

  .headerNav {
    display: none;
  }
  .mobileMenuWrapper {
    display: initial;
  }
  #logo {
    width: 144px;
  }
}
</style>
