// PhoneBox Account Area 2020
// 2020-11-23 - Chao
// Framewrork: Vue 2.0
// UI Library: Bootstrap Vue, ElementUI, Animate.style, vue-ellipse-progress
// Ajax: Axios

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./utils/axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import ElementUI from "element-ui";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/base.css";
import locale from "element-ui/lib/locale/lang/en";
import IdleVue from "idle-vue";
import VueEllipseProgress from "vue-ellipse-progress";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(ElementUI, { locale });
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(VueEllipseProgress);

const eventsHub = new Vue();
// auto logout when it is inactive
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 600000, // 60000 ms = one min
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
